.simple-categories-list {
  ul {
    margin: 0;
    padding-left: 24px;
    list-style-type: none;

    li {
      font-size: 16px;
      line-height: 1.5;
      margin-top: 16px;
      font-weight: 600;
      color: #8b8c8f;
      cursor: default;

      &:first-child {
        margin-top: 0;
      }

      &[data-category-id] {
        cursor: pointer;
        font-weight: normal;
        color: #131314;

        &.is-selected {
          font-weight: bold;
        }
      }
    }
  }

  &.is-titled {
    ul {
      li {
        &[data-category-id] {
          margin-left: 24px;
        }
      }
    }
  }
}
