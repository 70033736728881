.yesplz-text-search {
  position: relative;
  z-index: 300;
  display: none;

  &.is-active {
    display: block;
  }

  &-backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 100vh;
  }

  &-main {
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    padding: 25px 14px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: 768px) {
      padding: 48px;
      min-height: 450px;
    }
  }

  &-container {
    margin: 0 auto;
    position: relative;
    
    @media (min-width: 768px) {
      max-width: 1440px;
    }

    @media (min-width: 768px) {
    }
  }

  &-header {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: stretch;
      margin-bottom: 20px;
    }
  }

  &-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      width: 557px;
    }
  }

  &-close-button {
    background-color: transparent;
    border: 0;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;

    // @media (min-width: 768px) {
    //   position: static;
    // }

    &::after, &::before {
      content: '';
      display: block;
      width: 24px;
      height: 2px;
      background-color: #666666;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: 0;
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  &-form {
    display: flex;
    flex: 1;
    margin-right: 50px;
    position: relative;

    @media (min-width: 768px) {
      flex: auto;
      min-width: 450px;
      margin-right: 0;
    }

    > input {
      flex: 1;
      border: 0;
      border: 0;
      border-radius: 15px;
      height: 30px;
      line-height: 30px;
      padding-left: 38px;
      padding-right: 50px;
      font-size: 14px;
      background-color: #F4F4F4;

      &:focus {
        outline:none;
        outline-width: 0;
      }
    }

    > button {
      width: 30px;
      height: 30px;
      padding: 0;
      border: 0;
      text-indent: -9999px;
      border: 0;
      border-radius: 15px;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;

      &.submit {
        left: 0;
        background: url('../assets/search-icon.svg') 5px center no-repeat;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &.clear {
        &::after, &::before {
          content: '';
          display: block;
          width: 12px;
          height: 2px;
          background-color: #666666;
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -6px;
          margin-top: -2px;
        }
    
        &::before {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    flex-direction: column;
    padding: 0 18px;
    position: relative;

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 0;
      position: static;
    }
    
    .yesplz-suggestions {
      width: 100%;

      @media (min-width: 768px) {
        width: auto;

        position: absolute;
        top: 0;
        left: 0;

        padding-top: 8px;
        margin-bottom: 25px;
      }

      h5 {
        margin: 0;
        margin-right: 20px;
        margin-bottom: 15px;
        color: #8B8B8B;
        font-weight: 600;
        font-size: 16px;

        @media (min-width: 768px) {
          font-weight: 600;
          font-size: 18px;
          color: #000000;
          margin-bottom: 24px;
        }
      }

      ul {
        list-style-type: none;
        margin-bottom: 0;

        @media (min-width: 768px) {
          padding-top: 0;
        }

        li {
          margin-bottom: 0;
          border-radius: 15px;
          padding: 0;
          font-size: 16px;
          line-height: 16px;
          margin: 0 0 16px 0;
          cursor: pointer;
          white-space: nowrap;

          @media (min-width: 768px) {
            font-size: 14px;
            margin: 0 0 12px 0;
          }

          &.suggestion-type-brand {
            &::after {
              content: ' (brand)';
            }
          }

          &.suggestion-type-separator {
            margin: 1.4em 0;
            padding: 0;
            height: 1px;
            background-color: #E5E5E5;
          }
        }
      }
    }

    .yesplz-menu {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      @media (min-width: 768px) {
        width: auto;
      }

      ul {
        display: flex;
        list-style-type: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        @media (min-width: 768px) {
          margin-left: 0;
        }

        li {
          margin-right: 15px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 400;
          font-size: 14px;
          text-transform: uppercase;
          cursor: pointer;
          padding: 6px 0;
          position: relative;
          margin-bottom: 0;

          &.is-active {
            color: rgba(0, 0, 0, 1);

            &::after {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: rgba(0, 0, 0, 1);
              position: absolute;
              bottom: -1px;
              left: 0;
            }
          }

          &.sale {
            position: relative;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-right: 38px;
            text-transform: none;
            font-weight: 700;
            color: rgba(0, 0, 0, 1);
            margin-right: 0;

            &::before, &::after {
              display: block;
              content: '';
              position: absolute;
            }

            &::before {
              border: 2px solid #eeeeee;
              background-color: #eeeeee;
              border-radius: 9px;
              width: 24px;
              height: 12px;
              right: 0px;
              top: 2px;
            }

            &::after {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              top: 4px;
              right: 14px;
              left: auto;
              background: rgb(242,242,242);
              background-image: radial-gradient(circle, rgba(242,242,242,1) 0%, rgba(255,255,255,1) 100%);
              box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.4);
            }

            &.is-active {
              &::before {
                border: 2px solid #273123;
                background-color: #273123;
              }

              &::after {
                right: 2px;
                background-image: radial-gradient(circle, rgba(242,242,242,1) 0%, rgba(255,255,255,1) 100%), url('../assets/check-mark.svg');
              }
            }
          }
        }
      }

      &.sale-container {
        margin-bottom: 0;
        margin-left: 20px;
        position: absolute;
        top: 4px;
        right: 0;
        width: auto;

        @media (min-width: 768px) {
          position: static;
        }

        ul {
          border: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &-popular-queries, &-prompts-list {
    display: none;

    h4 {
      margin-top: 0;
      font-weight: 400;
      font-size: 18px;
    }

    ul {
      list-style-type: none;

      li {
        font-weight: bold;
        font-size: 16px;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.no-results {
    .yesplz-text-search-popular-queries, .yesplz-text-search-prompts-list {
      @media (min-width: 768px) {
        width: 557px;
        margin: 0 auto;
      }
    }
  }

  &-products {
    margin-bottom: 5px;

    .products-grid {
      .product-item {
        width: auto;
      }
    }
  }

  &-footer {
    text-align: center;

    > button {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      border: 0;
      background-color: transparent;
      text-decoration: underline;
    }
  }

  &.ai-mode {
    .yesplz-text-search-products {
      display: none !important;
    }
  }

  &.text-mode {
    .yesplz-ai-search-products {
      display: none !important;
    }
  }

  &.no-results {
    .yesplz-text-search-form {
      .submit {
        cursor: default;
      }
    }
    .yesplz-text-search-footer {
      display: none;
    }
    .yesplz-text-search-products {
      display: none;
    }
    .yesplz-text-search-menu-container {
      .yesplz-menu.sale-container {
        display: none;
      }
    }
  }

  &:not(.has-query) {
    &.text-mode {
      .yesplz-text-search-popular-queries {
        display: block;
      }
    }

    &.ai-mode {
      .yesplz-text-search-prompts-list {
        display: block;
      }
    }
  }
}

ul.txt-search-categories {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 15px;

  li {
    cursor: pointer;

    &.is-active {
      font-weight: bold;
    }
  }
}
.Collapsible {
  .Collapsible-header {
    position: relative;
    cursor: pointer;

    .toggler {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background: url('~@yesplz/core/src/wconcept/assets/wconcept-chevron.svg') center center no-repeat;
    }
  }

  &.open {
    .Collapsible-header {
      .toggler {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.yesplz-ai-message-container {
  display: flex;
  > img {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }

  p {
    margin-top: 6px;
  }

  p:empty {
    margin: 0;
  }
  
  .yesplz-ai-message-loader {
    display: none;
  }

  &.is-loading {
    .yesplz-ai-message-loader {
      display: block;

      &-text {
        color:rgba(0, 0, 0, 0.5)
      }
    }
  }
}