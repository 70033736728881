$c: 'MobileTabbed';

.MobileTabbedFilters-container {
  position: relative;
  z-index: 100;
  color: #000000;

  * {
    box-sizing: border-box;
  }
}

.MobileTabbedFilters {
  background-color: #ffffff;

  transition: transform 0.4s ease;

  &-Foldable {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 100dvh;

    display: flex;
    flex-direction: column;
  }

  &-pointer {
    z-index: 5;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: 48px;
    position: relative;
    background-color: #000000;
    color: #ffffff;

    &-actions {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
      }

      button {
        background: transparent;
        border: 0;
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        padding-right: 20px;
        padding-left: 20px;
        -webkit-tap-highlight-color: transparent;
      }
    }

    &-close {
      margin: 0 8px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        vertical-align: top;
        width: 16px;
        height: 16px;
      }
    }
  }

  .simple-presets-occasions-tags {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  &-tabs-wrapper {
    width: 100%;
    position: relative;
    flex: 1;
    display: flex;
    align-items: stretch;
  }

  &-tabs-container {
    flex: 1;
    position: relative;
  }

  &-tabs-slider {
    transition: transform 0.4s ease;
    width: 200%;
    display: flex;
    align-items: stretch;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &-tabs-titles {
    width: 50%;
    // height: calc(100dvh - 60px - 48px);
    position: relative;
    z-index: 1;
    flex: none;
    overflow-y: auto;
    padding-bottom: 16px;
    padding-top: 16px;

    .MobileTabbed-title {
      padding: 16px 20px;

      h4 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 21px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: block;

          img {
            display: block;
          }
        }

        .filter-value {
          margin: 0 20px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          color: #777777;
        }

        .tab-title-text {
          flex: 1;
        }
      }

      &.is-active {
        background-color: #f7f7f7;
        h4 {
          font-weight: 700;
        }
      }

      &.is-hidden {
        display: none;
      }
    }
  }

  // &.has-chips {
  //   .MobileTabbedFilters-tabs-titles {
  //     height: calc(100dvh - 60px - 48px - 61px);
  //   }
  // }

  &-tabs {
    flex: 1;
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 2;

    &.low-index {
      z-index: 0;
    }

    &-content {
      overflow-x: hidden;
      overflow-y: auto;

      // position: absolute;
      // top: 0;
      // bottom: 0;
      // left: 0;
      // right: 0;

      flex: 1;
      display: flex;
      align-items: stretch;

      > div {
        width: 100%;
      }
    }
  }

  &-footer {
    padding: 0;
    z-index: 10;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px -15px 30px 10px #ffffff;

    &-chips {
      .simple-presets-occasions-tags {
        margin-top: 0;
        background-color: #fafafa;
        padding: 15px 0;

        &.empty {
          display: none;
        }

        > div {
          padding: 0 20px;
        }

        ul {
          li {
            border-radius: 20px;
            background-color: #ffffff;
            border-color: #000000;
            margin-left: 0;
            font-size: 12px;
            line-height: 17px;
            text-transform: uppercase;

            &::after {
              opacity: 0.4;
            }
          }
        }
      }
    }

    button {
      border: 1px solid #000000;
      background-color: #000000;
      padding: 10px;
      color: #ffffff;
      width: 100%;
      height: 60px;
      flex: 1;
      box-shadow: none;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;

      svg {
        margin-left: 10px;
      }
    }
  }

  &.is-filter-selected {
    .MobileTabbedFilters-tabs-wrapper {
      margin-top: -48px;

      .MobileTabbedFilters-tabs-slider {
        transform: translate3d(-50%, 0, 0);

        .MobileTabbedFilters-tabs-titles {
          margin-top: 48px;
        }
      }
    }
  }
}

.#{$c} {
  display: none;
  overflow-y: auto;

  > div h3 {
    display: none;
  }

  &.is-active {
    display: block;
  }

  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: #f0f1f3;

    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    z-index: 4;
  }

  &-header {
    background-color: #000000;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10;

    button {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      padding: 0;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
      }

      &:hover {
        color: #ffffff;
      }

      &:active {
        box-shadow: none;
      }
    }

    > button {
      margin: 0 8px;
    }

    h2 {
      flex: 1;
      color: #ffffff;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin: 0;
    }

    &-action {
      color: #ffffff;
      margin: 0 20px;
    }

    &-actions {
    }
  }

  &-filter-content {
    padding: 16px 20px;
  }

  .ThumbnailPicker {
    padding: 0;
    padding-bottom: 15px;

    margin-left: 0;
    margin-right: 0;

    width: auto;
    justify-content: space-between;
    align-items: stretch;
    position: relative;

    .ThumbnailPickerOption {
      margin-right: 0;

      .ThumbnailPickerOption-thumbnail {
        background-color: #ffffff;
        border-radius: 50%;
        width: 80px;
        height: 80px;

        &::before {
          background-color: transparent;
          max-height: 100%;
          max-width: 100%;
          border: solid 1px #dddfe2;
          opacity: 1;
        }

        &--borderless {
          &::before {
            border-color: transparent;
          }
        }

        .ThumbnailPickerOption-imageWrapper {
          img {
            display: block;
            width: 27px;
            height: 27px;
          }

          .thumbnail-item {
            width: 80px;
            height: 80px;
          }

          .silhouetteTN {
            background-color: #f0f0f0;
            transform: scale(1.25);
            top: 9px;
            left: 10px;

            svg {
              g[fill='#01070D'] {
                fill: #f0f0f0;
              }

              *[fill='#FAFAFA'] {
                fill: #f0f0f0;
              }
            }

            &.all {
              background-color: #f0f0f0;
            }
          }
        }
      }

      &.is-active {
        .ThumbnailPickerOption-thumbnail {
          &::before {
            border: solid 2px #2f30eb;
          }
        }
      }

      &.reset {
        .ThumbnailPickerOption-thumbnail {
          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  .ColorPallete {
    padding: 0;
    display: grid;
    grid-template-columns: 64px 64px 64px 64px;
    justify-content: space-between;
    padding-bottom: 25px;

    .ColorButton-wrapper {
      width: auto;
    }

    .ColorButton {
      border: 0;
      box-shadow: none;
      width: 64px;

      &-color-wrapper {
        width: 64px;
        height: 64px;
      }

      &-color {
        width: 100%;
        height: 100%;
      }

      .ColorButton-mask::before {
        background-size: 14px 12px;
        display: none;
      }

      &.clear {
        .ColorButton-color-wrapper {
          border: solid 1px #dddfe2;

          img {
            width: 21px;
            height: 21px;
          }
        }
      }

      &.active {
        background: transparent !important;

        .ColorButton-color-wrapper {
          padding: 4px;
          border: solid 2px #000;
        }
      }
    }
  }

  &[data-filter='style'] {
    > h3 {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      margin-top: 0;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
  }

  &[data-filter='patterns'],
  &[data-filter='wash'] {
    .ThumbnailPicker {
      justify-content: flex-start;

      .ThumbnailPickerOption {
        &:nth-child(4n + 1) {
          padding-left: 0;
        }

        &:not(:nth-child(4n + 1)) {
          padding-left: calc((100% - (64px * 4)) / 3);
        }

        .ThumbnailPickerOption-thumbnail {
          width: 64px;
          height: 64px;

          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 64px;
              height: 64px;
            }
          }
        }

        &.is-active {
          .ThumbnailPickerOption-thumbnail {
            &::before {
              border-width: 2.5px;
            }
          }
        }

        &.reset {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        &.patterns-pattern {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 23px;
                height: 23px;
              }
            }
          }
        }

        &.patterns-solid,
        &.pattern-solid,
        &.patterns-detail,
        &.pattern-detail {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 38px;
                height: 38px;
              }
            }
          }
        }

        &.pattern-animal,
        &.pattern-washed_dark,
        &.pattern-washed_medium,
        &.pattern-washed_light,
        &.pattern-stripe,
        &.pattern-checker,
        &.pattern-graphic,
        &.pattern-colorblock,
        &.pattern-dot,
        &.pattern-fruit,
        &.pattern-repeat,
        &.pattern-sparkle,
        &.wide-image {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 64px;
                height: 64px;
              }
            }
          }
        }
      }
    }
  }

  .yesplz-body-parts {
    h3 {
      display: block;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      opacity: 0.6;
    }

    .ThumbnailPicker {
      display: grid;
      grid-template-columns: 80px 80px 80px;
      justify-content: space-between;
      .ThumbnailPickerOption {
      }
    }
  }

  .yesplz-price-range-container {
    padding: 20px 12px;
  }

  .yesplz-price-range-slider {
    &.noUi-horizontal {
      height: 4px;
    }

    &.noUi-target {
      border: 0;
      border-radius: 2px;
      background-color: #dddfe2;
    }

    .noUi-base {
      .noUi-connects {
        .noUi-connect {
          background-color: #131314;
        }
      }

      .noUi-origin {
        .noUi-handle {
          border: solid 2px #ffffff;
          background-color: #131314;
          width: 16px;
          height: 16px;
          top: -6px;
          right: -7px;

          .noUi-touch-area {
            width: 400%;
            height: 400%;
            transform: translate3d(-18px, -18px, 0);
          }
        }
      }
    }
  }

  .yesplz-price-from-to-filter {
    display: flex;
    justify-content: space-between;

    * {
      box-sizing: border-box;
    }

    span {
      display: block;
      width: 48%;

      input {
        width: 100%;
        border-radius: 8px;
        border: solid 1px #dddfe2;
        line-height: 16px;
        padding: 12px 16px;
        color: #8b8c8f;
        box-shadow: none;
        -webkit-appearance: none;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .simple-categories-list {
    padding-bottom: 25px;
  }

  .EditorsPicks {
    &-container {
      padding: 0;
      margin: 0 -5px;

      .EditorsPicks-Item {
        border-radius: 8px;
        box-shadow: 0px 0px 0px 1px #dddfe2;
        margin-left: 6px;
        margin-right: 6px;
        width: auto;
        padding: 8.5px 17px;

        h5 {
          font-size: 14px;
          text-transform: none;
        }

        &.is-active {
          box-shadow: 0px 0px 0px 1px #131314;
          background-color: #131314;

          h5 {
            color: #ffffff;
          }
        }
      }
    }
  }

  #mobile-tabbed-visual-filter {
    position: relative;

    &:before {
      content: '';
      width: 68.50828729281768%;
      height: 97.637795275590551%;
      background: #f0f0f0;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      margin: auto;
      z-index: 1;
      opacity: 1;
    }
  }

  .VisualFilter {
    position: relative;
    z-index: 10;
    -webkit-transform: translate3d(0,0,0);

    svg {
      -webkit-transform: translate3d(0,0,0);
      .vf-background {
        fill: #f0f0f0;
      }
    }
  }

  .yesplz-tree-view {
    margin-top: -16px;
    margin-left: -20px;
    margin-right: -20px;

    ul {
      -webkit-tap-highlight-color: transparent;
      li {
        margin-bottom: 0;
        position: relative;
        > span {
        }
      }
    }
  }

  &[data-filter='brand'] {
    padding: 0;
    .yesplz-brand-filter {
      height: calc(100vh - 48px - 100px);
      overflow: hidden;
      padding: 0;

      ul {
        height: calc(100vh - 48px - 100px - 56px - 69px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  .yesplz-brand-filter {
    > form {
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 1;

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 11px;
        right: 11px;
        width: 16px;
        height: 16px;
        background: url('~@yesplz/core-web/assets/svg/mobile-tabbed-search-icon.svg')
          center center no-repeat;
      }

      button.reset-button {
        position: absolute;
        display: none;
        padding: 0;
        box-shadow: none;
        background-color: #dddddd;
        border-color: #dddddd;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        top: 11px;
        right: 38px;

        &::after,
        &::before {
          content: '';
          display: block;
          background-color: #ffffff;
          width: 10px;
          height: 1px;
          margin-left: -5px;
          margin-top: -1px;
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          left: 50%;
        }

        &::before {
          transform: rotate(-45deg);
        }
      }

      &.is-not-empty {
        button.reset-button {
          display: block;
        }
      }
    }

    input {
      height: 40px;
      border-radius: 0;
      padding: 0 16px;
      font-size: 12px;
      color: #000000;
      background: none;

      &::placeholder {
        color: #777777;
      }

      &:focus {
        outline: none;
        outline-width: 0;
        border-color: #000000;
        box-shadow: none;
      }
    }

    .brands-selected {
      margin-left: -18px;
      margin-right: -18px;
      position: sticky;
      top: 64px;
      z-index: 2;

      span {
        background-color: #ffffff;
      }
    }

    ul {
      list-style-type: none !important;
      overflow: visible;
      max-height: none;

      li {
        cursor: pointer;
        position: relative;
        padding: 16px 0 16px 36px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        color: #777777;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        -webkit-tap-highlight-color: transparent;

        span {
        }

        &::before {
          display: block;
          content: '';
          width: 20px;
          height: 20px;
          background: url('~@yesplz/core-web/assets/svg/mobile-tabbed-checkbox.svg')
            transparent no-repeat center center;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0);
          margin: auto;
        }
        &::after {
          display: none;
        }

        &.active,
        &.is-selected {
          background-color: #ffffff;
          color: #000000;
          font-weight: 600;

          &::before {
            background-image: url('~@yesplz/core-web/assets/svg/mobile-tabbed-checkbox-checked.svg');
          }
        }

        &.is-disabled {
          opacity: 0.3;
          cursor: default;
        }

        &.header {
          padding: 12px 0;
          cursor: default;

          span {
            font-weight: bold;
            color: #000000;
          }

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }

  // &[data-filter="categories"] {
  //   .txt-search-categories {
  //     padding: 0;
  //   }
  // }

  &-tree-view {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #f7f7f7;
    transition: all 0.3s ease-in-out;

    ul {
      margin-bottom: 0;
      transition: all 0.3s ease-in-out;

      li {
        display: flex;
        align-items: stretch;
        margin-bottom: 0 !important;

        span {
          flex: 1;
          display: block;
          padding: 14px 18px;
          text-transform: uppercase;
        }

        i {
          display: block;
          width: 32px;
          position: relative;

          &::before,
          &::after {
            content: '';
            display: block;
            width: 12px;
            height: 2px;
            background-color: #777777;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -6px;
          }
          &::before {
            transform: rotate(45deg);
            margin-top: -4px;
          }
          &::after {
            transform: rotate(-45deg);
            margin-top: 4px;
          }
        }

        &.is-open {
          > span {
            font-weight: bold;
          }
        }

        ul {
          display: none;
        }

        &[data-tree-item-type='back'] {
          display: none;

          span {
            padding-left: 0;
          }

          i {
            transform: rotate(180deg);
            margin-left: 10px;
          }
        }

        &[data-tree-item-catid='Sale'] {
          > span {
            color: #ff0000;
          }
        }
      }
    }

    &.open-level-1 {
      left: -156px;

      > ul {
        width: 156px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #ffffff;

        li[data-tree-item-type='back'] {
          display: flex;
        }

        li[data-tree-item-type='all'] {
          display: none;
        }

        > li.is-open {
          background-color: #f7f7f7;

          > ul {
            background-color: #f7f7f7;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: calc(100vw - 156px);

            > li[data-tree-item-type='back'] {
              display: none;
            }

            > li[data-tree-item-type='all'] {
              display: flex;
            }
          }
        }
      }
    }

    &.open-level-2 {
      left: -156px;

      > ul {
        width: 156px;
        position: absolute;
        top: 0;
        left: -156px;
        bottom: 0;
        background-color: #ffffff;

        li[data-tree-item-type='back'] {
          display: flex;
        }

        li[data-tree-item-type='all'] {
          display: none;
        }

        > li.is-open {
          background-color: #f7f7f7;

          > ul {
            background-color: #ffffff;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 156px;

            > li.is-open {
              background-color: #f7f7f7;

              > ul {
                background-color: #f7f7f7;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                width: calc(100vw - 156px);

                > li[data-tree-item-type='back'] {
                  display: none;
                }

                > li[data-tree-item-type='all'] {
                  display: flex;
                }
              }
            }
          }
        }
      }
    }

    &.open-level-3 {
      left: -312px;

      > ul {
        width: 156px;
        position: absolute;
        top: 0;
        left: -156px;
        bottom: 0;
        background-color: #ffffff;

        li[data-tree-item-type='back'] {
          display: flex;
        }

        li[data-tree-item-type='all'] {
          display: none;
        }

        > li.is-open {
          background-color: #f7f7f7;

          > ul {
            background-color: #ffffff;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 156px;

            > li.is-open {
              background-color: #f7f7f7;

              > ul {
                background-color: #ffffff;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                width: 156px;

                > li.is-open {
                  background-color: #f7f7f7;

                  > ul {
                    background-color: #f7f7f7;
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 100%;
                    width: calc(100vw - 156px);

                    > li[data-tree-item-type='back'] {
                      display: none;
                    }

                    > li[data-tree-item-type='all'] {
                      display: flex;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.open-level-4 {
      left: -468px;

      > ul {
        width: 156px;
        position: absolute;
        top: 0;
        left: -156px;
        bottom: 0;
        background-color: #ffffff;

        li[data-tree-item-type='back'] {
          display: flex;
        }

        li[data-tree-item-type='all'] {
          display: none;
        }

        > li.is-open {
          background-color: #f7f7f7;

          > ul {
            background-color: #ffffff;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 156px;

            > li.is-open {
              background-color: #f7f7f7;

              > ul {
                background-color: #ffffff;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                width: 156px;

                > li.is-open {
                  background-color: #f7f7f7;

                  > ul {
                    background-color: #ffffff;
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 100%;
                    width: 156px;

                    > li.is-open {
                      background-color: #f7f7f7;

                      > ul {
                        background-color: #f7f7f7;
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 100%;
                        width: calc(100vw - 156px);

                        > li[data-tree-item-type='back'] {
                          display: none;
                        }

                        > li[data-tree-item-type='all'] {
                          display: flex;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
