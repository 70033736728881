.root {
  width: 'auto';

  span {
    margin-right: '15px';
  }

  .select {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }

    &::before, &::after {
      display: none !important;
    }

    i {
      cursor: pointer;
      position: absolute;
      right: 0;
      border-radius: 11px;
      border: 2px solid #666666;
      color: #666666;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    * {
      background-color: transparent !important;
    }

    :global {
      .MuiSelect-select.MuiSelect-select {
        padding-right: 30px;
        text-decoration: underline;
        font-family: Muli;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
