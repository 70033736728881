.root {
  height: calc(100vh - 65px);
  overflow: auto;

  .container {
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px;
  }

  .tree {
    margin-left: -20px;
  }
}