.ProductRow {
  display: flex;
  min-height: 480px;
  padding: 0 15px;

  .ProductRow-product {
    width: 285px;
    border-right: 1px solid #eae9ea;
    padding: 15px 0;
  }
  .ProductRow-recommended {
    position: relative;
    flex: 1;
    padding: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .ProductRow-recommended-container {
      .slick-slider {
        user-select: text;

        .slick-prev, .slick-next {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
          border: none;
          border-radius: 100%;
          bottom: auto;
          cursor: pointer;
          user-select: none;
          visibility: visible;
          background-color: #ffffff;

          top: 190px;
          z-index: 100;

          padding-top: 4px;

          text-align: center;

          &:before {
            content: '';
          }

          img {
            width: 8px;
            vertical-align: top;
          }

          &.slick-disabled {
            opacity: 0.4;
          }
        }
        .slick-prev {
          left: -8px;
        }
        .slick-next {
          right: -5px;
        }
      }
      .ReviewProduct-product-container {
      }
    }
  }
}
