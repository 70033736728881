.Register {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .right-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .right-header {
      font-size: 76px;
      font-weight: 800;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 6.5px;
      color: #000000;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      align-self: center;
      margin-top: 40px;

      .login-btn {
        width: 229px;
        margin: 8px;
        padding: 16px;
        border-radius: 5px;
        border: 3px solid #5b5b75;
        background-color: #5b5b75;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        
        &:hover {
          background-color: #ffffff;
          color: #5b5b75;
        }
      }
    }
    .lost-text {
      font-size: 11px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin-top: 32px;
    }
  }
}