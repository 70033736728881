.yesplz-body-parts {
  &.empty {
    visibility: hidden;
  }
}

.thumbnail-item {
  width: 80px;
  height: 80px;

  // width: 64px;
  // height: 64px;

  border-radius: 50%;
  overflow: hidden;
}

$col-stride: -80px;
$row-stride: -100px;

.silhouetteTN {
  width: 64px;
  height: 64px;
  display: inline-block;
  -webkit-background-size: auto;
  background-size: auto; // Show in original size
  background-repeat: no-repeat;

  transform: scale(1.25);
  position: absolute;
  top: 8px;
  left: 8px;

  overflow: hidden;
  border-radius: 50%;

  svg {
    position: absolute;
    top: 13px;
    left: 15px;
  }

  &.all {
    background-color: #fafafa;

    svg {
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }

  @include media-breakpoint-up(md) {
    transform: scale(1) translate3d(0, 0, 0);
    top: 1px;
    left: 1px;
  }

  &.checked {
    background-position: 0% 0%;
    opacity: 0.6;
    position: absolute;
    z-index: 2;
  }
  @for $row from 0 through 12 { // Max number of body parts
    @for $col from 0 through 12 { // Max number of choice per body part
      &.tn-#{$row}-#{$col} {
        background-position: $col-stride*$col $row-stride*$row;

        svg {
          top: $row-stride*$row;
          left: $col-stride*$col;
        }
      }
    }
  }
}
