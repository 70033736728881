.ReviewProduct-product-detail {
  height: calc(100vh - 65px);
  overflow: hidden;
  overflow-y: scroll;

  .ReviewProduct-product-detail-header {
    padding: 0 15px 15px;
  }

  .ReviewProduct-product-detail-container {
    width: 100%;
    min-width: 384px;
    height: auto;
    padding: 15px;
    margin-bottom: 25px;

    .horizontal-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
  
      .product-image {
        width: 304px;
        height: 451px;
      }
  
      .product-detail {
        margin-left: 50px;
        flex: 1;
        display: flex;
        flex: auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        // height: 451px;

        .product-name {
          margin-top: 20px;
        }
  
        .product-brand {
          margin-top: 8px;
        }

        .product-prediction-wrap {
          width: 100%;
          flex: 1;
          overflow: auto;
          padding: 15px;
          border: 1px solid rgba(0,0,0,0.2);
          background-color: rgba(0,0,0,0.01);
        }

        .product-prediction {
          margin-bottom: 8px;
          white-space: pre;
          font-size: 12px;
          line-height: 20px;
          color: rgba(0,0,0,0.84);
        }

        .product-price {
          margin-top: 15px;
          
          .original {
            margin-right: 20px;
          }
        }

        .product-prediction-tables {
          display: flex;
          justify-content: space-around;
          width: 80%;

          > div {
            width: 50%;

            table {
              tr {
                td {
                  padding: 0.4rem 2rem 0.4rem 0;

                  strong {
                    font-weight: semibold;
                  }
                }
              }
            }
          }
        }
      }
    }
  
    .product-image {
      position: relative;
  
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
  
      .bug-report-icon {
        display: inline-block;
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
      }
    }
  
    .product-id {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.1px;
      color: #000000;
    }
  
    .product-name {
      margin-bottom: 0;
      padding: 0;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b1b1d;
    }
  
    .product-brand {
      margin-bottom: 15px;
      padding: 0;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b1b1d;
    }
  
    .product-price {
      margin-bottom: 0;
      padding: 0;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.6px;
      color: #2F30EB;
  
      .original {
        color: #1b1b1d;
        text-decoration: line-through;
      }
    }
  }

  .ReviewProduct-product-detail-button-container,
  .ReviewProduct-product-detail-button-bottom-container {
    padding: 15px 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .MuiButton-root {
      border-radius: 21px;

      .MuiSvgIcon-root {
        margin-right: 15px;
      }
    }
  }

  .ReviewProduct-product-detail-button-bottom-container {
    justify-content: center;
    margin-bottom: 30px;
  }

  .product-list-container {
    padding: 15px 15px;

    .ReviewProduct-recommended {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      .ReviewProduct-product-container {
        padding: 0;
        margin-bottom: 25px;
        width: 254px;
      }
    }
  }
}
