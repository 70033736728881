$item-height: 34px;
$item-horizontal-margin: 5px;

.EditorsPicks {
  &.empty {
    display: none;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;

    .EditorsPicks-Item {
      width: calc(33.333% - #{16px + $item-horizontal-margin * 2});
      // height: $item-height / 2 - 1px;
      box-shadow: 0px 0px 0px 1px #1F1F1F;
      border-radius: 25px;
      margin: 8px $item-horizontal-margin;
      cursor: pointer;
      overflow: hidden;
      padding: 8px;

      div {
        text-align: center;
        line-height: 16px;

        h5 {
          margin: 0;
          color: #1F1F1F;
          display: inline-block;
          white-space: nowrap;
          line-height: 16px;
          font-weight: normal;
          font-size: 15px;
        }
      }

      &.is-active {
        box-shadow: 0px 0px 0px 1px #2F30EB;

        h5 {
          color: #2F30EB;
        }
      }

      &.reset {
        > div {
          display: none;
        }

        width: 34px !important;
        height: 34px !important;
        border-radius: 50% !important;
        background: url('~@yesplz/core-models/assets/svg/reset-icon.svg') center center no-repeat;
        background-size: 15px 15px;
      }
    }

    &.col-4 {
      width: 115%;

      .EditorsPicks-Item {
        width: calc(25% - #{$item-horizontal-margin * 2});
      }
    }

    &.col-5 {
      width: 140%;

      .EditorsPicks-Item {
        width: calc(20% - #{$item-horizontal-margin * 2});
      }
    }

    &.col-6 {
      width: 170%;

      .EditorsPicks-Item {
        width: calc(16.666% - #{$item-horizontal-margin * 2});
      }
    }
  }
}
