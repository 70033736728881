@import "~@splidejs/splide/dist/css/splide.min.css";

.yesplz-product-detail {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-image {
    img {}
  }
  &-description {
    h4 {}
    h2 {}
  }
  &-price {}
}

.yesplz-similar-products {
  display: flex;
}

.splide {
  .splide__arrows {
    .splide__arrow {
      background-color: transparent;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      &.splide__arrow--prev {
        left: -20px;
      }
      &.splide__arrow--next {
        right: -20px;
      }
    }
  }

  .splide__track {
    .splide__list {
      .splide__slide {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.yesplz-similar-products {
  display: flex;

  .yesplz-product-detail {
    padding: 10px;
    width: 25%;

    &-image {
      margin-bottom: 15px;
      img {
        width: 100%;
      }
    }
  }
}

.splide {
  .yesplz-product-detail {
    &-image {
      margin-bottom: 15px;
      img {
        width: 100%;
      }
    }
  }
}
