body.yesplz-stylist-open {
  overflow: hidden;

  @media (min-width: 768px) {
    overflow: auto;
  }
}

.yesplz-iframe {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2147483647;
  border: 0;
  display: none;
  width: 100%;
  height: 100dvh;

  @media (min-width: 768px) {
    max-height: 100vh;
    width: 411px;
    height: 780px;
    right: 42px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  @media (min-width: 1536px) {
    width: 494px;
    height: 936px;
  }

  &.is-open {
    display: block;
  }

  &.is-minimized {
    height: 56px;
    width: 300px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.yesplz-stylist-opener {
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: 0;
  color: #fff;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  z-index: 2147483647;
  box-shadow: none !important;
  outline: none;

  &:hover, &:focus, &:active {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  &.hidden {
    display: none;
  }
}
