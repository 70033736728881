.BaseDemo {
  display: block;
  margin: auto;
  height: 100vh;
  background: #ffffff;
  position: relative;
  overflow: hidden;

  &-header {
    color: #000000;
    height: 114px;
    padding-top: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid #4A4A4A;
    text-align: center;
  }

  &-logo {
    font-size: 41px;
    color: #000000;
    text-decoration: none;

    > small {
      font-size: 40px;
    }
  }

  &-content {
    height: calc(100% - 114px);
    position: relative;

    > div:first-child {
      height: 100%!important;
    }
  }

  &-contentOverlay {
    width: calc(100% - 30px);
    max-width: 1440px;
    position: absolute;
    height: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 999999;
    pointer-events: none;
  }

  &-contentOverlayInner {
    background: rgba(#1B1B1D, 0.9);
    display: block;
    width: calc(100% - 33.33vw);
    height: 100%;
    position: relative;
    pointer-events: auto;

    &::before {
      content: '';
      display: block;
      width: calc(100% - 29.1vw);
      height: 100%;
      position: absolute;
      right: 100%;
      top: 0;
      background: rgba(#1B1B1D, 0.9);
    }
  }

  &-tutorialItemWrapper {
    position: absolute;
    display: inline-block;
    top: 18px;
    right: 18px;
  }

  &-tutorialItem {
    font-size: 20px;
    color: white;
    margin-bottom: 40px;
    position: relative;
  }

  &-tutorialItemImage {
    position: absolute;
    left: 100%;
  }

  .ProductGrid > .LikeButton {
    display: none;
  }

  .VisualFilter .FilterPanel {
    svg {
      max-width: none;
    }
    .LikeButton {
      display: none;
    }
  }

  .ProductPage .LikeButton {
    display: none;
  }
}
