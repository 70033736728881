.ThumbnailPicker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  padding: 0 24px;

  .ThumbnailPickerOption {
    margin: 0;
    text-align: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    display: flex;
    flex-direction: column;
    align-items: center;

    .ThumbnailPickerOption-thumbnail {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      min-height: 48px;
      width: 80px;
      height: 80px;
      margin: 8px 0;

      @include media-breakpoint-up(md) {
        width: 64px;
        height: 64px;
      }

      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        border: 1px solid #1b1b1d;
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 50%;
        opacity: 0.06;
        z-index: 1;
        box-sizing: border-box;
      }

      .ThumbnailPickerOption-imageWrapper {
        position: relative;
        border-radius: 9999px;
        overflow: hidden;

        @include media-breakpoint-up(md) {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          display: block;
        }
      }

      &--borderless {
        &::before {
          border-color: transparent;
        }
      }
    }

    h5 {
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      letter-spacing: 0;
      margin-top: 4px;
      text-transform: capitalize;

      span {
        white-space: nowrap;
      }
    }

    &.is-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.is-active {
      > .ThumbnailPickerOption-thumbnail::before {
        opacity: 1;
        border-color: #2F30EB;
      }

      h5 {
        color: #2F30EB;
      }
    }
  }

  &--small-icons {
    .ThumbnailPickerOption {

      .ThumbnailPickerOption-thumbnail {
        width: 48px;
        height: 48px;
        margin: 16px;
      }
    }
  }

  &--with-chekmark {
    .ThumbnailPickerOption.is-active {
      .ThumbnailPickerOption-thumbnail {
        &::after {
          content: "";
          border-radius: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          top: 0;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("~@yesplz/core-models/assets/svg/check.svg");
        }
      }
    }
  }
}

.ThumbnailPicker-hidden {
  display: none;
}
