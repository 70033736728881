.yesplz-brand-filter {
  width: 100%;

  form {
    position: relative;

    input {
      width: 100%;
      height: 48px;
      font-size: 13px;
      line-height: 18px;
      padding: 9px 15px 9px 42px;
      border-radius: 8px;
      border: solid 1px #dddfe2;
      margin-bottom: 16px;
      font-family: inherit;
      background: url('~@yesplz/core-web/assets/images/search-icon.svg') 12px center no-repeat;

      &:focus {
        border-color: #000000;
      }
      &:focus-visible {
        outline: none;
      }
    }

    .reset-button {
      position: absolute;
      right: 46px;
      top: 11px;
      border: 0;
      width: 16px;
      height: 16px;
      box-shadow: none;
      padding: 0;
      display: none;
      background: url('~@yesplz/core/src/wconcept/assets/light-close-button.svg') center center no-repeat;
    }

    &.is-not-empty {
      .reset-button {
        display: block;
      }
    }
  }

  .brands-index-container {
    display: flex;
    margin-bottom: 30px;

    &.hidden {
      display: none;
    }

    .prev, .next {
      display: block;
      width: 15px;
      height: 30px;
      background: url('~@yesplz/core-web/assets/svg/brand-arrow-right.svg') right center no-repeat;
      cursor: pointer;

      &.disabled {
        opacity: 0.2;
      }
    }

    .prev {
      transform: translate3d(0, 1px, 0) rotate(180deg);
    }
  }

  &.no-search {
    form {
      display: none;
    }
  }

  .brands-index {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: calc(100vw - 40px - 30px);
    line-height: 20px;
    
    @media (min-width: 768px) {
      flex: 1;
      width: calc(100% - 30px);
    }

    span {
      font-size: 14px;
      display: inline-block;
      padding: 5px 10px;
      position: relative;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: #e3e3e3, $alpha: .3);
      }
      &:active {
        background-color: transparent;
      }
    }
  }

  &.no-index {
    .brands-index {
      display: none;
    }
  }

  .brands-selected {
    margin-left: -5px;
    margin-right: -5px;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0 15px;
    margin-bottom: 3px;

    span {
      margin: 8px 5px;
      font-size: 14px;
      display: inline-block;
      padding: 4px 20px 4px 4px;
      position: relative;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: #e3e3e3, $alpha: .3);
      }

      &:before, &:after {
        content: '';
        display: block;
        width: 1px;
        height: 10px;
        background-color: rgba($color: #000000, $alpha: .4);
        position: absolute;
        top: 50%;
        right: 10px;
      }

      &:before {
        transform: translateY(-50%) rotate(45deg);
      }
      &:after {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  .brands-index, .brands-selected {
  }

  ul {
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 20px;
    padding-bottom: 0;

    &.is-hidden {
      display: none;
    }

    li {
      span {
      }
    }
  }
}
