.simple-presets-occasions-tags {
  overflow: auto;
  white-space: nowrap;
  padding: 15px 20px 2px;
  margin-bottom: 0px;

  &.empty {
    display: none;
  }

  > div {
    display: flex;
    margin-right: 5px;
    overflow: auto;

    > ul {
      padding: 0;
      white-space: nowrap;

      > li {
        display: inline-block;
        border: 1px solid #F4F4F8;
        background-color: #F4F4F8;
        border-radius: 6px;
        padding: 5px 13px;
        position: relative;
        margin-top: 0;
        font-size: 13px;

        &.is-hidden {
          display: none;
        }

        &[data-remove] {
          display: none;
        }

        &.is-active {
          display: inline-block;
          border-color: #131314;
          font-weight: normal !important;
          padding-right: 36px;
          
          &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translate3d(0, -50%, 0);
            background: url('~@yesplz/core-web/assets/images/close-icon.svg') center center no-repeat;
          }
        }

        &.non-removable {
          padding-right: 13px;
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .simple-presets-occasions-tags {
    margin: 0;
    padding: 0;

    > div {
      overflow: visible;

      > ul {
        > li {
          &[data-type="occasion"], &[data-type="vibe"] {
            display: none;
          }
        }
      }
    }
  }
}