
// .yesplz-tree-view {
//   ul {
//     padding-left: 10px;

//     li {
//       cursor: pointer;

//       ul {
//         display: none;
//       }

//       &.active {
//         > ul {
//           display: block;
//         }
//       }

//       .toggler {
//         float: right;
//         display: block;
//         width: 25px;
//         height: 25px;
//         cursor: pointer;
//       }

//       > div.subcategories {
//         ul {
//           display: block;
//           max-height: fit-content !important;
//           margin-bottom: 0;
//           padding: 15px 0 10px 20px;
//         }
//       }

//       &.opened {
//         .toggler {}
//       }
//     }
//   }

//   > ul {
//     padding-left: 0;
//   }
// }

.yesplz-tree-view {
  ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    > li {
      margin-bottom: 0;
      position: relative;

      > span {
        margin: 0;
        color: #000;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        display: inline-block;
        width: 100%;
        position: relative;
        padding: 8px 0;
      }

      > .toggler {
        background: url('~@yesplz/core/src/wconcept/assets/wconcept-chevron.svg') center center no-repeat;
        background-color: transparent;
      }

      > a {
        @media (min-width: 768px) {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
        }
      }

      &.disabled {
        opacity: 0.3;
        
        > span {
          cursor: not-allowed !important;
          
          &:hover, &:active {
            text-decoration: none !important;
          }
        }
      }

      &.auto-disabled {
        display: none !important;
      }

      &.active {
        > span {
          font-weight: 400;
          border-bottom: 0;

          &::before {
            background-image: url('~@yesplz/core-web/assets/svg/mobile-tabbed-checkbox-checked.svg');
          }
        }

        &.has-subcategories {
          > ul {
            display: none;
          }
        }
      }

      &.has-children {
        position: relative;
        > span {

          &::before {
            display: none;
          }
        }

        .toggler {
          position: absolute;
          top: 10px;
          right: 20px;
          width: 20px;
          height: 20px;
        }

        > ul {
          display: none;
        }

        &.opened {
          > .toggler {
            transform: rotate(180deg);
          }

          > ul {
            display: block;
          }
        }
      }

      &.subcategories > ul > li, &.all-item {
        > span {
          padding: 8px 20px 8px 50px;

          &::before {
            display: block;
            content: "";
            width: 20px;
            height: 20px;
            background: url('~@yesplz/core-web/assets/svg/mobile-tabbed-checkbox.svg') transparent no-repeat center center;
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translate3d(0, -50%, 0);
            margin: auto;
          }
        }

        &.active {
          > span {
            font-weight: 400;
            border-bottom: 0;
  
            &::before {
              background-image: url('~@yesplz/core-web/assets/svg/mobile-tabbed-checkbox-checked.svg');
            }
          }
        }
      }
    }
  }
}
