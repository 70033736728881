.root {
  margin: 5px 0;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
  background-color: #ffffff;
  cursor: default;

  .drag-handler {
    // transform: rotate(90deg);
    margin-right: 10px;
  }

  .label {
    line-height: 30px;
    flex: 1;

    span.clickable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    a {
      display: none;
      color: #000000;
      text-decoration: none;
      font-size: small;
      margin-left: 20px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .buttons {
    border-right: 1px solid #ccc;
    padding-left: 10px;
    padding-right: 10px;

    &:last-child {
      border-right: 0;
      padding-right: 0;
    }

    button {
      height: 20px;
      width: 20px;
      margin-right: 0;

      :global {
        .MuiIconButton-label {
          .MuiSvgIcon-root {
            transform: scale(0.7);
          }
        }
      }
    }

    :global {
      .MuiIconButton-colorPrimary {
        color: #3f51b5 !important;

        span {
          color: #3f51b5 !important;
        }
      }
    }
  }

  &:hover {
    background-color: #f5f5f5;

    .label a {
      display: inline;
    }
  }
}
