$prefix: 'ResponsiveFilter';

.#{$prefix} {
  box-sizing: content-box;

  .#{$prefix}-wrapper {
    .#{$prefix}-container {
      .#{$prefix}-advanced {
        .#{$prefix}-tab-switch {
          display: flex;
          margin: 0;
          padding: 0 10px;
          flex-direction: row;
          flex-wrap: nowrap;

          & > li {
            flex-grow: 1;

            display: inline-flex;
            flex-direction: column;
            align-content: center;
            justify-content : center;

            list-style-type: none;

            color: #1b1b1d;
            text-align: center;
            font-size: 11px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 2px;
            text-align: center;
            text-transform: uppercase;
            // white-space: nowrap;
            padding: 0;
            margin: 0;
            min-height: 36px;
            min-width: 85px;
            border-bottom: 2px solid transparent;

            position: relative;

            cursor: pointer;

            &.disabled {
              display: none;
            }

            &.disabled-lg {
              @include media-breakpoint-up(md) {
                display: none;
              }
            }

            &.active {
              font-weight: 900;
              border-bottom-color: #1b1b1d;
            }

            &.changed:after {
              display: inline-block;
              content: '';
              background: url('~@yesplz/core-models/assets/svg/selector_dot.svg');
              width: 11px;
              height: 11px;
              position: absolute;
              top: 4px;
              margin-left: 4px;
            }
          }
        }

        .#{$prefix}-tabs {
          background-color: #fff;
          box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07);

          > div {
            display: none;
            padding: 15px 0 0;

            &.visible-lg {
              @include media-breakpoint-up(md) {
                display: block;
              }
            }

            &.active {
              display: block;

              h3 {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    flex-shrink: 0;
    width: 324px;
    height: 100%;
    border-right: 1px solid #eae9ea;
    overflow: visible;
    overflow-y: scroll;

    .#{$prefix}-wrapper {
      .#{$prefix}-backdrop,
      .#{$prefix}-header,
      .#{$prefix}-advanced-toggle
      {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    width: 0;
    height: 0;
    overflow: hidden;

    .#{$prefix}-wrapper {
      display: none;

      &.active {
        display: block;
      }

      .#{$prefix}-backdrop {
        width: 100vw;
        height: 100vh;
        opacity: 0.2;
        background-color: #06000e;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 9999998;
      }

      .#{$prefix}-container {
        background-color: #F9F9F9;

        position: fixed;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999999;

        #visual-filter {
          position: relative;
          height: 250px;

          &::before {
            content: '';
            width: 200px;
            height: 200px;
            background: #F4F4F4;
            border-radius: 100%;
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 0;
          }

          svg {
            position: relative;
            z-index: 1;
          }
        }
      }

      .#{$prefix}-header {
        display: flex;
        justify-content: space-between;

        padding: 12px 20px;
        background-color: #ffffff;

        &-close, &-blank {
          width: 40px;
          height: 40px;
        }

        &-category {
          text-align: center;
          position: relative;
          padding-top: 10px;

          &-label {
            display: inline-block;
            font-size: 13px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 2px;

            &:after {
              content: '';
              display: inline-block;
              border-top: 4px solid #1b1b1d;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              margin-left: 8px;
              vertical-align: middle;
            }
          }

          select {
            position: absolute;
            opacity: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
          }
        }
      }

      .#{$prefix}-tooltips-toggle {
        width: 43px;
        height: 43px;
        position: absolute;
        background-color: #ffffff;
        border-radius: 22px;
        border: 0;
        top: 76px;
        right: 18px;
        // right: 50%;
        // transform: translateX(130px);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        outline: none;
      }

      .#{$prefix}-advanced-toggle {
        font-weight: 900;
        font-size: 11px;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;

        background: #FFFFFF;
        display: block;
        padding: 7px 24px;
        margin: 0 auto 24px;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.04);
        border: none;
        border-radius: 14px;
        outline: none;

        position: relative;
        z-index: 1;

        & > img {
          vertical-align: middle;
          position: relative;
          top: -1px;
          margin-left: 4px;
        }
      }

      .#{$prefix}-advanced {
        display: none;

        position: relative;
        z-index: 1;

        .#{$prefix}-tab-switch {
          display: block;
          overflow-x: scroll;
          white-space:nowrap;

          & > li {
            display: inline-block;
            width: 102px;
            flex-grow: unset;
          }
        }

        .#{$prefix}-tabs {
          > div {
            overflow-x: scroll;
            overflow-y: hidden;
            height: 119px;

            h3 {
              display: none;
            }

            .ThumbnailPicker {
              display: block;
              white-space:nowrap;
              padding: 0;

              .ThumbnailPickerOption {
                padding: 0 6px;

                &:nth-child(1):nth-last-child(3),
                &:nth-child(2):nth-last-child(2),
                &:nth-child(3):nth-last-child(1) {
                  margin: 0 calc(((100vw / 3) - 108px) / 2);
                }

                &:nth-child(1):nth-last-child(2),
                &:nth-child(2):nth-last-child(1) {
                  margin: 0 calc(((100vw / 3) - 108px) / 2);
                }
              }

              &--small-icons {
                .ThumbnailPickerOption {
                  .ThumbnailPickerOption-thumbnail {
                    width: 48px;
                    height: 48px;
                    margin: 16px 0;
                  }
                }
              }
            }

            .ColorPallete {
              padding: 0 5px 15px;

              .ColorButton {
                margin-left: 3px;
                margin-right: 3px;
              }
            }
          }
        }
      }

      &.advanced-opened {
        .#{$prefix}-advanced-toggle {
          display: none;
        }
        .#{$prefix}-advanced {
          display: block;
        }
      }
    }
  }
}

.ResponsiveFilter .ResponsiveFilter-wrapper .ResponsiveFilter-advanced {
  .ResponsiveFilter-tabs > div .ColorPallete .ColorButton {
    margin-left: 0;
    margin-right: 0;

    @media (min-width: 330px) {
      margin-left: 3px;
      margin-right: 3px;
    }

    @media (min-width: 410px) {
      margin-left: 7px;
      margin-right: 7px;
    }

    @include media-breakpoint-up(md) {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
}
