ul.list {
  padding-left: 20px;

  li {
    list-style-type: none;

    > ul {
      display: none;
    }

    &.creator {
      padding: 10px 0;
      display: flex;
      justify-content: center;
    }

    &.open {
      > ul {
        display: block;
      }
    }
  }
}
