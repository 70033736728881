.DotLoader {
  text-align: center;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
}

.DotLoader-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #4A4A4A;
  border-radius: 50%;
  margin: 0 5px;
  transform: scale(0);
  vertical-align: top;
  animation: dotAnimation 1000ms ease infinite 0ms;
}

.DotLoader-dot:nth-child(2) {
  animation: dotAnimation 1000ms ease infinite 300ms;
}

.DotLoader-dot:nth-child(3) {
  animation: dotAnimation 1000ms ease infinite 300ms;
}

@keyframes dotAnimation {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
