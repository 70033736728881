.Admin-Home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .left-bg {
    position: absolute;
    z-index: 9;
    left: 10%;
  }
  .right-bg {
    position: absolute;
    z-index: 9;
    right: 18%;
  }
  .main-container {
    z-index: 10;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;

    .menu-title {
      font-size: 32px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 4.1px;
      color: #000000;
      margin-bottom: 3rem;
    }

    .back-btn {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 4.1px;
      color: #000000;
      background: transparent;
      text-decoration: underline;
      border: none;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      &:hover {
        text-decoration: none;
      }
    }

    .menu-group {}
  }
}